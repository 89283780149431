<template>
  <UnitsFrame>
    <MazoForm
      @setCreated="setCreated"
      @setItems="setItems"
    />
  </UnitsFrame>
</template>

<script>
import UnitsFrame from '../../../components/units/UnitsFrame.vue'
import MazoForm from '../../../components/mazos/MazoForm.vue'

export default {
  components: { UnitsFrame, MazoForm },
  data: () => ({
    mazo: {
      titulo: '',
      descripcion: '',
      flashcards: [],
      created: false
    }
  }),
  methods: {
    setItems (payload) {
      this.mazo = payload
    },
    setCreated () {
      this.created = true
    }
  },
  async beforeRouteLeave (to, from, next) {
    const items = this.mazo.flashcards.map(item => item.enunciado !== '' && item.respuesta !== '')
    console.log('this.mazo ', this.mazo)
    if ((items.length !== 0 || this.mazo.titulo !== '' || this.mazo.descripcion !== '') && !this.created) {
      const confirm = await this.$root.$confirm({
        title: '¿Salir de la página?',
        message: 'Aún no creaste el mazo. ¿Seguro que quieres salir sin crearla?',
        acceptText: 'Si',
        cancelText: 'No'
      })
      if (confirm) {
        next()
      }
    } else {
      next()
    }
  }
}
</script>
